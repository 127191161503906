.header {
    width: 100%;
    padding: 2%;
    background-color: rgba(11, 221, 236, 0.932);
    color: black;
    text-align: center;
}

.cell-font {
    font-size: 2em;
}

.sigPad {
    margin: auto;
}